import styled from "styled-components";

export const Wrapper = styled.div`
div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
button{
    background-color: #F67263;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
    border: none;
    margin: 0 10px 10px 10px;
    margin-top: 30px;
    color: white;
    border-radius: 20px;
}
`
