import {Wrapper} from "./styled-index"

function Fixed() {
    return(
        <Wrapper>
        <a  href="tel:+998977501133">
        <box-icon name='phone-call' color='#ffffff'></box-icon>
        </a>
        <a  href="https://t.me/mirpotolkov_uz">
        <box-icon type='logo' color='#ffffff' name='telegram'></box-icon>
        </a>
        </Wrapper>
    )
}
export default Fixed